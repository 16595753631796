import React, {Component, Context} from "react";
import {connect, ConnectedProps} from "react-redux";
import {createStructuredSelector, OutputSelector} from "reselect";
// @ts-ignore
import KeyHandler, {KEYUP} from "react-key-handler";
import classnames from 'classnames';

import {openFrameModal} from "../../redux/actions";
import "./style.scss";
import {
  selectCameras,
  selectCurrentTurnaround,
  selectInferenceTimestamp, selectCurrentStandId, selectFrameModalData, selectCurrentTurnaroundId, selectDetections
} from "../../redux/selectors";
import {showStandClear} from "../../services/config";
import {formatTime} from "../../services/time";
import Modal from '../Modal';
import FrameUnavailable from "../FrameUnavailable";
import Frame from "../Frame";
import BBox from "../BBox";
import TimeFormatter from "../TimeFormatter";
import { detectionToEvents } from "../../services/data";
import DetectionEvent from "../../models/event";
import Detection from "../../models/detection";
import Turnaround from "../../models/turnaround";
import {FrameModalData} from "../../models/common";
import {State} from "../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";

type ModalState = {
  camera: string
  step: number
  failCounter: number
  timestamp: number
  unavailable: boolean,
  events: DetectionEvent[],
  loaded: boolean,
  detection?: Detection
}

type ReduxProps = {
  inferenceTimestamp?: {[key:string]:number}
  cameras: string[],
  detections: Detection[],
  turnaround?: Turnaround | null,
  turnaroundId?: string,
  standId?: string,
  data: FrameModalData
}
const mapStateToProps = createStructuredSelector<State, ReduxProps>({
  inferenceTimestamp: selectInferenceTimestamp,
  cameras: selectCameras,
  detections: selectDetections,
  turnaround: selectCurrentTurnaround,
  turnaroundId: selectCurrentTurnaroundId,
  standId: selectCurrentStandId,
  data: selectFrameModalData as OutputSelector<State,FrameModalData,any>
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, unknown, Action>) => ({dispatch})

const connector = connect(mapStateToProps,mapDispatchToProps);

class FrameModal extends Component<ConnectedProps<typeof connector>,ModalState> {
  constructor(props:ConnectedProps<typeof connector>,context: any) {
    super(props, context);

    const {data: {detection, timestamp}} = this.props;

    const state: ModalState = {
      camera: this.props.cameras[0],
      step: -1000,
      failCounter: 0,
      timestamp,
      unavailable: false,
      events: [],
      loaded: false,
    };

    if (detection) {
      state.detection = detection;
      if (detection.bbox && props.cameras.includes(detection.bbox.camera))
        state.camera = detection.bbox.camera;
      state.events = detectionToEvents(detection).filter(e=>e.label);
    }
    this.state = state;
  }

  selectCamera(camera: string) {
    if (this.state.camera !== camera)
      this.setState({camera, failCounter: 0, unavailable: false})
  }

  next(ev: KeyboardEvent, x: number) {
    ev.stopPropagation();
    let {timestamp} = this.state;
    const {turnaroundId,inferenceTimestamp} = this.props;
    if(!inferenceTimestamp) {
      return;
    }
    if (ev.shiftKey)
      x = 10;
    timestamp = timestamp + 1000 * x;

    if ((!turnaroundId && timestamp > inferenceTimestamp.common) || timestamp > Date.now())
      return;
    this.selectTimestamp(timestamp, 1000 * x);
  }

  prev(ev: any, x: number) {
    ev.stopPropagation();
    if ((ev as MouseEvent | KeyboardEvent).shiftKey)
      x = 10;
    let ts = this.state.timestamp;
    ts = ts - 1000 * x;
    this.selectTimestamp(ts, -1000 * x);
  }

  selectTimestamp(timestamp: number, step: number = -1000) {
    if (timestamp !== this.state.timestamp)
      this.setState({timestamp, step, loaded: false, failCounter: 0, unavailable: false})
  }

  onFail() {
    let {failCounter, timestamp, step} = this.state;
    failCounter++;
    if (failCounter < 3) {
      timestamp += step;
      this.setState({timestamp, failCounter});
    }
    else
      this.setState({unavailable: true})
  }

  isTimestampInsideTurn() {
    const {timestamp} = this.state;
    const turnaround = this.props.turnaround as Turnaround;
    const {start, end} = turnaround;

    if (start < timestamp && (!end || timestamp < end))
      return true;
    return false;
  }

  isStandClear() {
    const {timestamp} = this.state;
    const {detections} = this.props;
    let standNotClear = timestamp && detections.find(d => d.type === 'stand_not_clear'
      && ((!d.end && d.start < timestamp)
        || (d.start < timestamp && d.end && timestamp < d.end)));
    if (standNotClear)
      return false;
    return true;
  }

  enableShowStandClear() {
    const {standId} = this.props;
    if (showStandClear.includes(standId))
      return true;
    return false;
  }

  close = ()=>{
    this.props.dispatch(openFrameModal(null))
  }

  renderAlerts() {
    const {data:{detection}} = this.props;

    if(detection?.confidence && detection.confidence < 0.6)
      return (
        <div className="alert warning">
          <i className="far fa-exclamation-triangle"/>
          ATTENTION! LOW CONFIDENCE {`(${Math.round(detection.confidence * 100)}%)`}
        </div>
      );
    if(this.enableShowStandClear() && this.isStandClear() && !this.isTimestampInsideTurn() && !detection)
      return (
        <div className="alert success">
          <i className="fas fa-check"/>
          Stand clear
        </div>
      );
    if(this.enableShowStandClear() && !this.isStandClear() && !this.isTimestampInsideTurn() && !detection)
      return (
        <div className="alert error">
          <i className="far fa-exclamation-triangle"/>
          Stand not clear
        </div>
      );
    return null;
  }

  renderToolbar() {
    const {timestamp} = this.state;

    return <div className="toolbar">
      <KeyHandler keyEventName={KEYUP} keyValue={'ArrowLeft'} onKeyHandle={(ev: KeyboardEvent) => this.prev(ev, 1)}/>
      <a className={'prev10'} onClick={(ev: React.MouseEvent) => this.prev(ev, 10)}>
        <i className="fas fa-fast-backward"/>
      </a>
      <a className={'prev'} onClick={(ev) => this.prev(ev, 1)}>
        <i className="fas fa-backward"/>
      </a>

      <span><TimeFormatter format={'DD MMM YYYY - HH:mm:ss'} time={timestamp}/></span>

      <KeyHandler keyEventName={KEYUP} code={'ArrowRight'} onKeyHandle={(ev:any) => this.next(ev, 1)}/>
      <a className={'next'} onClick={(ev:any) => this.next(ev, 1)}>
        <i className="fas fa-forward"/>
      </a>
      <a className={'next10'} onClick={(ev:any) => this.next(ev, 10)}>
        <i className="fas fa-fast-forward"/>
      </a>
    </div>
  }

  renderEventsInfo() {
    let {events} = this.state;
    if(!events.length)
      return null;

    return (
      <table className='events-info'>
        <thead>
          <tr>
            <th>event</th>
            <th>conf</th>
            <th>timestamp</th>
            <th></th>
          </tr>
        </thead>
        {events.map(({timestamp,label,confidence}) =>(
          <tr key={label}>
            <td className='label'>{label}</td>
            <td className='confidence'>
              {confidence ? <span className={confidence < 0.8 ? 'low':''}>{Math.ceil(confidence*100) + '%'}</span> : ''}
            </td>
            <td className='date-time'>
              <div className='date'><TimeFormatter format={'DD MMM YYYY'} time={timestamp}/></div>
              <div className='time'><TimeFormatter format={'HH:mm:ss'} time={timestamp}/></div>
            </td>
            <td className='action'>
              <a onClick={()=>this.selectTimestamp(timestamp)}>
                <i className="fas fa-play"/>
              </a>
            </td>
          </tr>
        ))}
      </table>
    )
  }

  render() {
    let {camera, detection, timestamp, unavailable, loaded} = this.state;
    const {cameras} = this.props;

    let bbox;
    if (
      detection
      && ((detection.end && timestamp <= detection.end) || timestamp >= detection.start)
      && detection.bbox && detection.bbox.camera === camera
    )
      bbox = detection.bbox;

    return (
      <Modal onClose={this.close} className={'frame-modal'}>
        <div className={'modal-header'}>
          {detection && detection.label}
          {/*<span className={'badge'}>*/}
          {/*  +7min*/}
          {/*</span>*/}
          <a className={'close-btn'} onClick={this.close}>
            <i className="fal fa-times"/>
          </a>
        </div>

        {this.renderEventsInfo()}

        <div className={'modal-section-title'}>
          video

          <div className={'cameras'}>
            {cameras.map(c=>(
              <a className={classnames({active: c === camera})} onClick={()=>this.selectCamera(c)} key={c}>{c}</a>
            ))}
          </div>
        </div>

        {!unavailable && (
          <Frame
            timestamp={timestamp}
            camera={camera}
            key={camera}
            onFail={()=>this.onFail()}
            onSuccess={()=>this.setState({loaded:true})}
          >
            {loaded && bbox && <BBox bbox={bbox}/>}
          </Frame>
        )}

        {unavailable && (
          <FrameUnavailable msg={`Image at \n${formatTime(timestamp)}\n is unavailable.`}/>
        )}

        {this.renderToolbar()}

        {this.renderAlerts()}
      </Modal>
    );
  }
}

export default connector(FrameModal);