// @ts-ignore
import chroma from "chroma-js";
import update from "immutability-helper";
import _ from "lodash";
import {
  SET_CURRENT_PLANE_TYPE,
  SET_TURNAROUNDS,
  SET_CURRENT_STAND_ID,
  SERVER_UNAVAILABLE,
  SET_RESOLUTION,
  UPDATE_REALTIME_DATA,
  SET_PTS_FOR_PLANE,
  TOGGLE_CONFIDENCE_ON_TIMELINE,
  SET_REPLAY_TIMESTAMP,
  OPEN_FRAME_MODAL,
  SET_CURRENT_TURNAROUND_ID,
} from "./constants";
import ReduxAction from "../models/reduxAction";
import CameraOutage from "../models/cameraOutage";
import Turnaround from "../models/turnaround";
import Detection from "../models/detection";
import {RealtimeState} from "../models/state";
import moment from "moment";

export function getTime(str:string) {
  return moment("12.25.2019 " + str,"MM.DD.YYYY HH:mm:ss").subtract(73,'s').toDate().getTime();
}

function makeEvent(id:any,ts:any,label:string,group:string):Detection {
  return {
    id,
    confidence: 0.99,
    start: getTime(ts),
    end: getTime(ts)+1,
    label,
    startLabel: label,
    endLabel: "",
    group,
    type: label,
    startType: label,
    bbox: null
  }
}

function makeDetection(id:any,ts:any,label:[string],group:string): Detection {
  return {
    id,
    confidence: 0.99,
    start: getTime(ts[0]),
    end: getTime(ts[1])+1,
    label: label[0],
    startLabel: "",
    endLabel: "",
    group,
    type: label[0],
    startType: label[0],
    endType: "",
    bbox: null
  }
}

const initialState : RealtimeState = {
  eventsLabels: (window as any).eventsLabels,
  resolution: {},
  pts: {},
  showConfidenceOnTimeline: false,
  outages: [],
  colors: {},
  serverUnavailable: false,
  detections: [
    makeDetection(1,['00:00:05','00:01:19'],['Hangar doors open'],'General'),
    makeDetection(2,['00:00:19','00:01:17'],['Stand not clear'],'General'),
    makeDetection(3,['00:00:14','00:00:19'],["Presents loading"],"Cargo"),
    makeDetection(4,['00:00:22','00:01:13'],['Chocks placed'],'Service'),
    makeDetection(5,['00:00:23','00:01:13'],['Sledge on stand'],'General'),
    makeDetection(6,['00:00:23','00:01:21'],['Deer connected'],'General'),
    makeDetection(7,['00:00:25','00:00:32'],['Engines starting'],'General'),
    makeDetection(8,['00:00:39',"00:01:13"],["Santa on stand"],"General"),
    makeDetection(9,['00:00:48','00:01:21'],["Santa on board"],"Passengers"),
    makeEvent(10,'00:01:00',"Take off approved","General"),
    makeDetection(11,['00:01:00','00:01:13'],['Engine acceleration'],'General'),
    makeEvent(12,'00:01:13',"Take off","General"),
    makeEvent(13,'00:00:32',"Raindeer ready","General"),
  ],
  turnarounds: [
    {
      id:"1",
      loaded:true,
      start: getTime('00:00:00'),
      end: getTime('00:01:30'),
      authorized: true,
      pushbackMaxSpeed: 10,
      videos: {
        'camera': {
          url: '/video.mp4',
          speed:1
        }
      }

    }
  ],
  allTurnaroundsLoaded: false
};

(function() {
  let keys = _.uniq(Object.keys((window as any).operationsLabels));
  let colors = chroma
    .scale(["#fa7900", "#3e7d95"])
    .mode("lch")
    .colors(keys.length);
  initialState.colors = {};
  keys.forEach((k, i) => (initialState.colors[k] = colors[i]));
})();

export default function(state:RealtimeState = initialState, action: ReduxAction):RealtimeState {
  switch (action.type) {
    case SET_TURNAROUNDS:
      let items = _.orderBy(action.turnarounds, ['start'], ['desc']);
      return {
        ...state,
        turnarounds: items,
        allTurnaroundsLoaded: action.finished
      };
    case SET_CURRENT_STAND_ID:
      return {
        ...state,
        currentTurnaroundId: undefined,
        // turnarounds: undefined,
        // detections: [],
        outages: [],
        inferenceTimestamp: undefined,
        lastImageTimestamp: undefined,
        allTurnaroundsLoaded: false
      }
    case SET_CURRENT_TURNAROUND_ID:
      return {
        ...state,
        currentTurnaroundId: action.id
      };
    case UPDATE_REALTIME_DATA:
      // if(!state.detections)
      //   state = {...state, detections: []};
      // if(!state.outages)
      //   state = {...state, outages: []};
      // state =  updateStateWithData(state,action);
      return state;
    case SET_REPLAY_TIMESTAMP:
      return {...state, replayTimestamp: action.timestamp};
    case SET_CURRENT_PLANE_TYPE:
      state = update(state, {
        planeType: {$set: action.planeType},
      });
      return state;
    case OPEN_FRAME_MODAL:
      return {...state, frameModalData: action.data};
    case SERVER_UNAVAILABLE:
      return {...state, serverUnavailable: action.value};
    case SET_RESOLUTION:
      return update(state,{
        resolution: {
          [action.camera]: {$set: action.value}
        }
      });
    case SET_PTS_FOR_PLANE:
      return update(state,{
        pts: { [action.planeType]: {$set: action.data} }
      });
    case TOGGLE_CONFIDENCE_ON_TIMELINE:
      return {...state, showConfidenceOnTimeline: !state.showConfidenceOnTimeline};
    default:
      return state;
  }
}

function updateStateWithData(state: RealtimeState,action: ReduxAction) {
  const {detections,outages,inferenceTimestamp, lastImageTimestamp,turnarounds,startTs,endTs} = action;

  state = {...state};

  let newDetectionsIds = (detections as Detection[]).map(e=>e.id);
  state.detections = [
    ...state.detections.filter(({start:ts,id})=>(ts<startTs || (endTs && ts > endTs)) && !newDetectionsIds.includes(id)),
    ...detections
  ];

  let newOutagesIds = (outages as CameraOutage[]).map(e=>e.id);
  state.outages = [
    ...state.outages.filter(({start:ts,id})=>(ts<startTs || (endTs && ts > endTs)) && !newOutagesIds.includes(id)),
    ...outages
  ];

  let turnsIds = (turnarounds as Turnaround[]).map(t=>t.id);
  state.turnarounds = [
    ...(state.turnarounds || []).filter(({id})=>!turnsIds.includes(id)),
    ...turnarounds
  ];
  state.turnarounds =  _.orderBy(state.turnarounds, ['start'], ['desc']);

  if(inferenceTimestamp){
    state.inferenceTimestamp = inferenceTimestamp;
  }
  if(lastImageTimestamp)
    state.lastImageTimestamp = lastImageTimestamp;

  return state;
}
