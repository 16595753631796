import axios from "./axios";
import {
  filterStands,
  stands,
  standsLabels,
  timelineMiddlewares,
  turnaroundsRequestLimit,
} from "./config";
import {
  parseCameraOutage,
  parseDetections, parseTurnaround,
  parseTurnarounds,
} from "./apiParse";
import {toMilliSecondsOrNull} from "./time";
import _,{fromPairs} from 'lodash';
import Stand from "../models/stand";
import Turnaround from "../models/turnaround";
import {AxiosResponse} from "axios";
import Alert from "../models/alert";
import {camelCaseKeys} from "./data";
import moment from "moment";

export function getStands():Promise<Stand[]> {
  if(stands)
    return Promise.resolve(stands);

  return axios.get(`/api/stands.json`).then((resp:AxiosResponse)=>{
    let stands = resp.data;

    // let fields = [
    //   ['id',textSchema,true],
    //   ['camera_ids',textArraySchema,true]
    // ];
    // stands.forEach((stand:any)=>validateAndFix(stand,fields));

    if(standsLabels) {
      let sortedItems = Object.keys(standsLabels).map(id=>{
        let item = stands.find((s:any)=>s.id === id);
        return item && {...item,label: standsLabels[item.id] }
      }).filter(i=>i);
      sortedItems.push(...stands.filter((i:any)=>!standsLabels[i.id]));
      stands = sortedItems;
    }

    stands = stands.map((stand:any)=>({
      id: stand.id,
      label: stand.label || stand.id,
      cameras: stand.camera_ids
    }));

    if(!filterStands)
      return stands;
    return stands.filter((s:Stand)=>filterStands.includes(s.id));
  });
}

export function getTurnarounds(standId:string,before?:number,limit = turnaroundsRequestLimit): Promise<Turnaround[]>{
  let url = `/api/stands/${standId}/turnarounds.json`;
  if(before)
    url += '&before=' + Math.floor(before/1000);
  return axios.get(url).then((resp:AxiosResponse)=> {
    return parseTurnarounds(resp.data,standId);
  });
}

export function getTurnaround(standId:string,turnId:string): Promise<Turnaround> {
  let url = `/api/stands/${standId}/turnarounds/${turnId}`;
  return axios.get(url).then((resp:AxiosResponse)=> {
    return parseTurnaround(resp.data);
  });
}

export async function timeline(
  standId:string,
  startTs:number,
  endTs?:number | null,
  types = ['detections','turnarounds','camera_outages']
) {
  debugger;
  startTs = startTs / 1000;
  const joinedTypes = types.map(t=>'types=' + t).join('&');
  let url = `/api/stands/${standId}/timeline.json`;
  if (endTs) {
    endTs = endTs / 1000;
    url += `&end_ts=${endTs}`;
  }

  return axios.get(url).then((resp:AxiosResponse)=> {
    // let fields = [
    //   ['last_prediction_ts',positiveFloatArraySchema,false, val=>Object.values(val)],
    // //  ['last_image_ts',positiveFloatArraySchema,true, val=>Object.values(val)],
    //   ['current_ts',positiveFloatSchema,false],
    // ];
    // resp = validateAndFix(resp,fields);

    let {turnarounds,detections,camera_outages,last_prediction_ts,last_image_ts,current_ts} = resp.data;

    turnarounds = parseTurnarounds(turnarounds,standId);
    turnarounds.forEach((t:Turnaround)=>t.loaded = true);


    Object.entries(last_prediction_ts).forEach(([cam,ts])=>{
      if(!last_image_ts[cam])
        last_image_ts[cam] = ts;
    });
    last_prediction_ts = fromPairs(Object.entries(last_prediction_ts).map(([key,val])=>[key,toMilliSecondsOrNull(val as string)]));
    last_image_ts = fromPairs(Object.entries(last_image_ts).map(([key,val])=>[key,toMilliSecondsOrNull(val as string)]));

    detections = parseDetections(detections);

    camera_outages = camera_outages.map(parseCameraOutage);

    timelineMiddlewares.forEach(middleware=>{
      try {
        [detections,turnarounds] = middleware(detections,turnarounds,last_prediction_ts,_);
      }catch(er){
        console.error(er);
      }
    })

    let res = {
      inferenceTimestamp: last_prediction_ts,
      lastImageTimestamp: last_image_ts,
      absoluteTime:toMilliSecondsOrNull(current_ts),
      turnarounds,
      detections,
      outages: camera_outages
    };
    return res;
  });
}

export function getImgToken(): Promise<string> {
  let url = '/api/account/token.json';
  return axios.get(url).then((resp:AxiosResponse)=> resp.data.token);
}


export function getAlerts(params = {}): Promise<Alert[]> {
  let url = '/api/stands/incidents';
  return axios.get(url,{params}).then((resp:AxiosResponse)=> resp.data.map((item:any)=>{
    item = camelCaseKeys(item);
    item.ts *= 1000;
    item.alertType = item.incidentType;
    delete item.incidentType;
    return item;
  }));
}

export async function makeExport(
  standId:string,
  startTs:number,
  endTs:number,
) {
  startTs = startTs / 1000;
  endTs = endTs / 1000;

  let url = `/api/stands/${standId}/export?start_ts=${startTs}&end_ts=${endTs}`;

  return axios.get(url,{timeout:60*5*1000}).then((resp:AxiosResponse)=> {
    let {turnarounds,detections} = resp.data;
    turnarounds = parseTurnarounds(turnarounds, standId);
    detections = parseDetections(detections);
    return [detections,turnarounds];
  });
}

// export function getFlightInfo(standId:string,ts:number) {
//   //Demo hack
//   let res:any ={
//     "stand_id": "lgw-104",
//     "inbound_flight": {
//       "aircraft_type": "a320",
//       "arrival_airport": "lhr",
//       "origin_date": "2020-01-01"
//     },
//     "outbound_flight": {
//       "departure_airport": "lhr",
//       "origin_date": "2020-01-01"
//     },
//     "actual_touchdown_utc": 1597230384.0,
//     "actual_on_block_utc": 1597230384.0,
//     "estimated_on_block_utc": 1597230384.0,
//     "target_on_block_utc": 1597230384.0,
//     "scheduled_on_block_utc": 1597230384.0,
//     "actual_off_block_utc": 1597230384.0,
//     "estimated_off_block_utc": 1597230384.0,
//     "target_off_block_utc": 1597230384.0,
//     "scheduled_off_block_utc": 1597230384.0
//   };
//
//   switch (standId) {
//     case 'ups':
//       res.inbound_flight.aircraft_type = 'B744';
//       res.inbound_flight.company_iata = '5X';
//       res.inbound_flight.departure_airport = 'HNL';
//       res.inbound_flight.flight_number = '34';
//
//       res.outbound_flight.aircraft_type = 'B744';
//       res.outbound_flight.company_iata = '5X';
//       res.outbound_flight.arrival_airport = 'ICN';
//       res.outbound_flight.flight_number = '33';
//       break;
//     case 'qantas':
//       res.inbound_flight.aircraft_type = 'B767';
//       res.inbound_flight.company_iata = 'QF';
//       res.inbound_flight.departure_airport = 'PER';
//       res.inbound_flight.flight_number = '888';
//
//       res.outbound_flight.aircraft_type = 'B767';
//       res.outbound_flight.company_iata = 'QF';
//       res.outbound_flight.arrival_airport = 'PER';
//       res.outbound_flight.flight_number = '1891';
//       break;
//     case 'sin_air':
//       res.inbound_flight.aircraft_type = 'A333';
//       res.inbound_flight.company_iata = 'SQ';
//       res.inbound_flight.departure_airport = 'PER';
//       res.inbound_flight.flight_number = '229';
//
//       res.outbound_flight.aircraft_type = 'A333';
//       res.outbound_flight.company_iata = 'SQ';
//       res.outbound_flight.arrival_airport = 'PER';
//       res.outbound_flight.flight_number = '230';
//       break;
//     case 'aa':
//       // SOBT 1830
//       res.inbound_flight.aircraft_type = 'B757';
//       res.inbound_flight.company_iata = 'AA';
//       res.inbound_flight.departure_airport = 'MCO';
//       res.inbound_flight.flight_number = '657';
//
//       res.outbound_flight.aircraft_type = 'B757';
//       res.outbound_flight.company_iata = 'AA';
//       res.outbound_flight.arrival_airport = 'MIA';
//       res.outbound_flight.flight_number = '601';
//       break;
//     default:
//       res.inbound_flight.aircraft_type = 'B757';
//       res.inbound_flight.company_iata = 'FX';
//       res.inbound_flight.departure_airport = 'IND';
//       res.inbound_flight.flight_number = '1755';
//
//       res.outbound_flight.aircraft_type = 'B757';
//       res.outbound_flight.company_iata = 'FX';
//       res.outbound_flight.arrival_airport = 'EWR';
//       res.outbound_flight.flight_number = '1901';
//       break;
//   }
//
//   res = camelCaseKeys(res);
//
//   res.aircraftType = res.outboundFlight.aircraftType;
//   [
//     'actualOffBlockUtc',
//     'actualOnBlockUtc',
//     'actualTouchdownUtc',
//     'estimatedOffBlockUtc',
//     'estimatedOnBlockUtc',
//     'scheduledOffBlockUtc',
//     'scheduledOnBlockUtc',
//     'targetOffBlockUtc',
//     'targetOnBlockUtc'
//   ].forEach(field => {
//     res[field] = res[field] ? res[field] * 1000 : null
//   });
//
//   res.scheduledOffBlockUtc = moment("18:55","HH:mm").toDate().getTime();
//   if(standId === 'aa')
//     res.scheduledOffBlockUtc = moment("18:30","HH:mm").toDate().getTime();
//
//   return Promise.resolve(res);
// }
