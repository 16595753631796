import Detection from "../models/detection";
import Turnaround from "../models/turnaround";

const defaults = {
  videoDropdown: true,
  showOperationsModel: true,
  blinkProbability: true,
  updateRate: 1000000000,
  timelineNotReadyMessage: 'No turnarounds have been detected yet',
  timeFormat: 'Local',
  requestTimeout: 10000,
  maxTriesUntilError: 3,
  googleAnalyticsId: 'UA-140165153-1',
  showDetectionTimestamp: false,
  showDetectionGap: false,
  notifications: false,
  enablePts: false,
  gaRefreshRate: 10*1000,
  // redWarning: 'This is a POC and still under development. Some events are still being calibrated.',
  showStandClear: [],
  filterSingleEvents: true,
  pushNotifications: false,
  speedUnits: 'kph',
  alarumEnabled: false,
  turnaroundsRequestLimit: 70,
  disableVideoStreamBBox: false,
  disableMap: false,
  mapGates: {},
  breakDetections: [],
  camerasOrder: ['-s'],
  showTurnaroundTimingPanel: false,
  enableSidebar: true,
  timelineMiddlewares: [],
  showStandClearAlert: false
};

const OVERIDES_PATH = 'configOverrides';

// @ts-ignore
export const getConfigOverrides = () => JSON.parse(window.localStorage.getItem(OVERIDES_PATH)) || {};

const config = {
  ...defaults,
  // ...window.appConfig,
  ...getConfigOverrides()
};

if(config.imagesDecayTime)
  config.imagesDecayTime *= 3600*1000;
if(config.imagesLifeTime)
  config.imagesLifeTime *= 3600*1000;

config.timeFormat = config.timeFormat.toUpperCase();

if(!config.alarumUrl){
  config.alarumUrl = 'wss://alarum.' + window.location.host;
}

if(!config.flightStatusUrl){
  config.flightStatusUrl = 'https://flights.' + window.location.host;
}

export const SENTRY_DSN = config.SENTRY_DSN;
export const APRON_ENV = config.APRON_ENV;
export const updateRate = config.updateRate;
export const videoDropdown = config.videoDropdown;
export const enablePts = config.enablePts;
export const timeFormat = config.timeFormat;
export const stands = config.stands;
export const filterStands = config.filterStands;
export const showDetectionGap = config.showDetectionGap;
export const showDetectionTimestamp = config.showDetectionTimestamp;
export const requestTimeout = config.requestTimeout;
export const maxTriesUntilError = config.maxTriesUntilError;
export const mergeOperations = config.mergeOperations;
export const disableImages = config.disableImages;
export const turnaroundsStartTimestamp = config.turnaroundsStartTimestamp;
export const standsLabels = config.standsLabels;
export const redWarning = config.redWarning;
export const openID = config.openID;
export const notifications = config.notifications;
export const gaRefreshRate = config.gaRefreshRate;
export const frozen = config.frozen;
export const showStandClear = config.showStandClear;
export const filterSingleEvents = config.filterSingleEvents;
export const pushNotifications = config.pushNotifications;
export const alarumUrl = config.alarumUrl;
export const flightStatusUrl = config.flightStatusUrl;
export const speedUnits = config.speedUnits;
export const alarumEnabled = config.alarumEnabled;
export const minOperationConfidence = config.minOperationConfidence;
export const ignoreMinOperationConfidenceOps = config.ignoreMinOperationConfidenceOps;
export const turnaroundsRequestLimit = config.turnaroundsRequestLimit;
export const disableVideoStreamBBox = config.disableVideoStreamBBox;
export const logoUrl = config.logoUrl;
export const mapGates = config.mapGates;
export const breakDetections = config.breakDetections;
export const disableMap = config.disableMap;
export const camerasOrder = config.camerasOrder;
export const filterCameras = config.filterCameras;
export const showTurnaroundTimingPanel = config.showTurnaroundTimingPanel;
export const enableSidebar = config.enableSidebar;
export const timelineMiddlewares : ((a:Detection[],b: Turnaround[],c:any,_:any)=>[Detection[],Turnaround[]])[] = config.timelineMiddlewares;
export const showStandClearAlert = config.showStandClearAlert;

// @ts-ignore
window.configManager = {
  set: (key:string,value: string) => {
    let obj = getConfigOverrides();
    if(value != null)
      obj[key] = value;
    else
      delete obj[key];

    window.localStorage.setItem(OVERIDES_PATH,JSON.stringify(obj));
    window.location.reload();
  },
  display: () => console.log(getConfigOverrides()),
  reset: () => {
    window.localStorage.removeItem(OVERIDES_PATH);
    window.location.reload();
  }
};