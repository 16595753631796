import React, {Component} from "react";
import {connect} from "react-redux";
import classnames from 'classnames'

import {
  selectCameras,
  selectLastImageTimestamp
} from "../../redux/selectors";
import {formatTime, formatTimeDiff, now} from "../../services/time";

import "./style.scss";
import FrameContainer from "../FrameContainer";
import Loader from "../Spinner";
import {createStructuredSelector} from "reselect";
import {loadImageAjax} from "../../services/images";
import FrameUnavailable from "../FrameUnavailable";
import {State} from "../../redux/store";

const HOURS_18 = 18*60*60*1000;

type StreamState = {
  camera: string,
  loading: boolean,
  dataUrl: string | null,
  restricted: boolean,
  loadedTs: number | null,
  lastRequestedTs?: number
}

type Props = {
  cameras: string[],
  lastImageTs?: {[key:string]:number}
}
const mapStateToProps = createStructuredSelector<State,Props>({
  cameras: selectCameras,
  lastImageTs: selectLastImageTimestamp,
});

const connector = connect(mapStateToProps);

class TurnaroundStream extends Component<Props,StreamState> {
  readonly state: StreamState;
  private mounted = true;
  private intervalId?: NodeJS.Timeout;

  constructor(props:Props) {
    super(props);

    this.state = {
      dataUrl: null,
      loadedTs: null,
      camera: props.cameras[0],
      loading: true,
      restricted: false
    };
  }

  componentDidMount() {
    this.updateDataUrl();
    this.intervalId = setInterval(()=>this.forceUpdate(),1000);
  }

  componentDidUpdate(prevProps:Props, prevState:StreamState) {
    if(prevState.camera !== this.state.camera) {
      this.updateDataUrl();
      return;
    }

    if(!this.props.lastImageTs)
      return;
    const newTs = this.props.lastImageTs[this.state.camera];
    if(!this.state.loading &&  newTs !== this.state.loadedTs && newTs !== this.state.lastRequestedTs)
      this.updateDataUrl();
  }

  componentWillUnmount() {
    this.mounted = false;
    if(this.intervalId)
      clearInterval(this.intervalId);
  }

  updateDataUrl() {
    const {camera} = this.state;
    if(!this.props.lastImageTs)
      return;
    const ts = this.props.lastImageTs[camera];

    this.setState({loading: true, lastRequestedTs: ts});

    loadImageAjax(camera,ts).then(url=>{
      if(!this.mounted || camera !== this.state.camera)
        return;

      this.setState({
        loadedTs: ts,
        dataUrl: url,
        restricted: false
      })
    },(er)=>{
      if(er.status === 403 || er.code === 403)
        this.setState({restricted: true})
    }).finally(()=>{
      this.setState({loading: false});
    });
  }

  render() {
    const {camera,dataUrl,loading,loadedTs, restricted} = this.state;
    const {cameras} = this.props;

    if(restricted)
      return <FrameUnavailable msg={'Forbidden'}/>;

    return (
      <FrameContainer camera={camera} className={'turnaround-stream'}>
        {!dataUrl && loading && <Loader/>}

        {dataUrl && (
          <img
            src={dataUrl}
            className={'frame'}
            alt=''
          />
        )}
        <div className={'toolbar'}>
          <div className={'cameras'}>
            {cameras.map(c=>(
              <a
                className={classnames({active:c === camera})}
                onClick={()=>this.setState({camera:c,dataUrl: null,loadedTs:null})}
                key={c}
              >
                {c}
              </a>
            ))}
          </div>
          {loadedTs && (
            <div className={'time'}>
              {formatTime(loadedTs, now()-loadedTs > HOURS_18)}({formatTimeDiff(loadedTs, now())})
            </div>
          )}
          {loadedTs && now()-loadedTs < 60000 && (
            <div className={'live-indicator'}>
              <i className="fas fa-circle"/>
              LIVE
            </div>
          )}
        </div>
      </FrameContainer>
    );
  }
}

export default connector(TurnaroundStream);