import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Route, useHistory,useParams} from "react-router-dom";

import {loadTurnarounds} from "../../redux/actions";
import {
  selectCurrentStandId,
  selectServerUnavailable, selectStands,
} from "../../redux/selectors";

import Spinner from "../Spinner";
import ErrorBoundary from "../ErrorBoundary";
import Turnaround from '../Turnaround';

import StandUpdater from "../StandUpdater";
import {SET_CURRENT_STAND_ID} from "../../redux/constants";
import StandToolbar from "../StandToolbar";
import ErrorMessage from "../ErrorMessage";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../../redux/store";
import {Action} from "redux";
import ContactFormPanel from '../ContactForm';

type RouterParams = {
  standId?:string
}

const Stand:React.FC = () => {
  const [ready,setReady] = useState(false);
  const history = useHistory();
  const {standId} = useParams<RouterParams>();
  const standIdFromStore = useSelector(selectCurrentStandId);
  const dispatch: ThunkDispatch<State,any,Action> = useDispatch();
  const stands = useSelector(selectStands);
  const serverUnavailable = useSelector(selectServerUnavailable);

  useEffect(()=>{
    setReady(false);

    if(!stands.find(s=>s.id === standId)){
      history.push('/' + stands[0].id);
    }else {
      dispatch({
        type: SET_CURRENT_STAND_ID,
        id: standId
      });

      setReady(true);
    }
  },[standId]);

  if(serverUnavailable && !ready)
    return <ErrorMessage/>;

  if(!ready || standId !== standIdFromStore)
    return <Spinner/>;

  return <>
    {standId === 'qantas' && <ContactFormPanel/>}
    {/*<StandUpdater key={standId}/>*/}
    <ErrorBoundary>
      {/*<StandToolbar/>*/}
      <ErrorBoundary>
        <Route path="/:standId/:turnId?" component={Turnaround}/>
      </ErrorBoundary>
    </ErrorBoundary>
  </>;
}

export default Stand;
