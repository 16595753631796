import {last} from 'lodash';

import {
  getStands,
  getTurnarounds,
  getImgToken,
  timeline,
  getTurnaround
} from "../services/api";
import {turnaroundsRequestLimit, updateRate} from "../services/config";
import {
  ADD_STANDS,
  SERVER_UNAVAILABLE,
  SWITCH_TIME_FORMAT,
  SET_TURNAROUNDS,
  SET_RESOLUTION,
  SET_USER,
  SET_IMG_TOKEN,
  UPDATE_REALTIME_DATA,
  SET_PTS_FOR_PLANE,
  SET_REPLAY_TIMESTAMP,
  OPEN_FRAME_MODAL,
  SET_CURRENT_TURNAROUND_ID
} from "./constants";
import {
  selectCurrentStandId,
  selectInferenceTimestamp,
  selectCurrentTurnaround,
  selectTurnarounds, selectAllowedTurnarounds,
} from "./selectors";
import {openID} from "../services/config";
import userManager from "../services/userManager";
import {now, setTimeOffset, timeOffset} from "../services/time";
import {TIME_FORMAT_KEY} from '../services/constants';
import store, {State} from "./store";
import Stand from "../models/stand";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import Turnaround from "../models/turnaround";
import {User} from "oidc-client";
import {FrameModalData} from "../models/common";

type ThunkResult<ReturnType = void> = ThunkAction<ReturnType, State, unknown, Action>;

export function loadStands(): ThunkResult {
  return (dispatch) => {
    getStands().then(
      (stands: Stand[]) => {
        dispatch({
          type: ADD_STANDS,
          stands
        });
      },
      () => {
        setTimeout(() => dispatch(loadStands()), updateRate);
      });
  };
}

export function setCurrentTurnaround(id?: string): ThunkResult {
  return (dispatch, getState) => {
    dispatch({
      type: SET_CURRENT_TURNAROUND_ID,
      id
    });

    let turn = selectCurrentTurnaround(getState());
    if (!turn)
      return;
    if (id && !turn.loaded) {
      dispatch(loadRealtimeData(turn.start, turn.end as number));
    }
    if (!id) {
      let inferenceTs = selectInferenceTimestamp(getState());
      dispatch(setReplayTimestamp(inferenceTs ? inferenceTs.common : now()));
    } else if (id)
      dispatch(setReplayTimestamp(turn.start));

    let allowedTurns = selectAllowedTurnarounds(getState());
    if(allowedTurns.indexOf(turn) === allowedTurns.length-1)
      dispatch(loadMoreTurnarounds());
  }
}

export function loadTurnarounds(): ThunkResult<Promise<void>> {
  debugger;
  return (dispatch, getState) => {
    let standId = selectCurrentStandId(getState()) as string;

    return getTurnarounds(standId).then(
      (turnarounds: Turnaround[]) => {
        if (standId !== selectCurrentStandId(getState()))
          return;

        dispatch({
          type: SET_TURNAROUNDS,
          turnarounds,
          finished: turnarounds.length < turnaroundsRequestLimit
        });
      },
      (er: Error) => {
        if (standId !== selectCurrentStandId(getState()))
          return;
        setTimeout(() => dispatch(loadTurnarounds()), updateRate);
        throw er;
      });
  }
}

export function loadMoreTurnarounds(): ThunkResult<Promise<Turnaround[]>> {
  return (dispatch, getState) => {
    let standId = selectCurrentStandId(getState()) as string;
    let oldestTurn = last(selectTurnarounds(getState()));
    return getTurnarounds(standId, oldestTurn && oldestTurn.start).then((newTurns: Turnaround[]) => {
      if (standId !== selectCurrentStandId(getState()))
        return [];

      let oldTurns = selectTurnarounds(getState());
      oldTurns = oldTurns.filter(t=>!newTurns.find(t2=>t2.id === t.id));
      let turns = [...oldTurns,...newTurns];

      dispatch({
        type: SET_TURNAROUNDS,
        turnarounds: turns,
        finished: newTurns.length < turnaroundsRequestLimit
      });
      return turns;
    })
  }
}

export function searchTurn(turnId: string): ThunkResult<Promise<Turnaround | null>> {
  return async function (dispatch:ThunkDispatch<State,any,Action>, getState) {
    const standId = selectCurrentStandId(getState()) as string;
    let turn = await getTurnaround(standId, turnId);

    if (!turn)
      return null;

    const turns = [...(selectTurnarounds(getState()) as Turnaround[])];
    // @ts-ignore
    let count = Math.floor((last(turns).start / 1000 - turn.start) / (3600));
    do {
      // @ts-ignore
      const ts = last(turns).start;
      const tmp = await getTurnarounds(standId, ts, count);
      count = turnaroundsRequestLimit;
      turns.push(...tmp);
    } while (!turns.find(t => t.id === turnId));

    dispatch({
      type: SET_TURNAROUNDS,
      turnarounds: turns
    });
    return turn;
  }
}

export const loadRealtimeData = (startTs: number, endTs?: number): ThunkResult<Promise<void>> => (dispatch, getState) => {
  let standId = selectCurrentStandId(getState()) as string;
  let requestStartTs = Date.now();

  return timeline(standId, startTs, endTs).then((resp: any) => {
    let {detections, inferenceTimestamp, absoluteTime, turnarounds,outages, lastImageTimestamp} = resp;

    if (standId !== selectCurrentStandId(getState()))
      return;

    if (!timeOffset && absoluteTime) {
      let localTime = Date.now();
      let requestDuration = localTime - requestStartTs;
      absoluteTime = absoluteTime + requestDuration / 2;
      setTimeOffset(absoluteTime - localTime);
    }

    dispatch({
      type: UPDATE_REALTIME_DATA,
      detections,
      inferenceTimestamp,
      lastImageTimestamp,
      turnarounds,
      outages,
      startTs,
      endTs,
    });
  })
};

export const setReplayTimestamp = (timestamp: number) => ({
  type: SET_REPLAY_TIMESTAMP,
  timestamp
});

export function serverUnavailable(value: boolean) {
  return {
    type: SERVER_UNAVAILABLE,
    value
  }
}

export const switchTimeFormat = (format:string):ThunkResult => (dispatch) => {
  window.localStorage.setItem(TIME_FORMAT_KEY, format);

  dispatch({
    type: SWITCH_TIME_FORMAT,
    format
  })
}

export function setResolution(camera:string, value:[number,number]) {
  return {
    type: SET_RESOLUTION,
    camera,
    value
  };
}

export function setUser(user:User) {
  return {
    type: SET_USER,
    user
  }
}

export function initImgToken():ThunkResult {
  return dispatch => {
    dispatch(loadImgToken());
    setInterval(() => dispatch(loadImgToken()), 15 * 60 * 1000);
  }
}

export function loadImgToken():ThunkResult {
  return dispatch => {
    console.log("update img token");
    getImgToken().then((token:string) => {
      dispatch({
        type: SET_IMG_TOKEN,
        token
      })
    })
  }
}

export function logout():ThunkResult {
  return dispatch => {
    if (!window.confirm('Are you sure you want to log out?'))
      return;
    userManager.removeUser().then(() => {
      setTimeout(() => {
        //Unless app overwrites logout url
        const url = openID.authority.replace('/connect', '/logout');
        window.location = url;
      })
    });
  }
}

export const setPtsForPlane = (planeType:string, data:any) => ({
  type: SET_PTS_FOR_PLANE,
  planeType,
  data
});

export function openFrameModal(data: FrameModalData | null) {
  return {type: ""}; //disable frame modal on demo
  return {
    type: OPEN_FRAME_MODAL,
    data
  }
}
