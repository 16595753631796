import React, {Component, ReactNode} from "react";
import {createStructuredSelector} from "reselect";
import {connect, ConnectedProps} from "react-redux";
import classnames from 'classnames';

import {selectReplayTimestamp, selectImgToken, selectCurrentTurnaround} from "../../redux/selectors";
import Spinner from "../Spinner";
import {setReplayTimestamp, setResolution} from "../../redux/actions";

import "./style.scss";
import {isDesktopSafari, isEdge, isIE11, isIOS} from "../../services/platform";
import FrameContainer from "../FrameContainer";
import {loadImageAjax} from "../../services/images";
import FrameUnavailable from "../FrameUnavailable";
import Turnaround from "../../models/turnaround";
import {Action} from "redux";
import Video from "../../models/video";
import {State} from "../../redux/store";
import {ThunkDispatch} from "redux-thunk";

export type PlayerState = {
  loading: boolean,
  playing: boolean,
  speeds: number[],
  showPreview: boolean,
  videoReady:boolean,
  speed: number,
  previewFailed: boolean,
  previewUrl?: string,
  unavailable: boolean
}

type ReduxProps = {
  token?: string,
  turnaround?: Turnaround | null,
  timestamp?: number
}
const mapStateToProps = createStructuredSelector<State,ReduxProps>({
  token: selectImgToken,
  turnaround: selectCurrentTurnaround,
  timestamp: selectReplayTimestamp
});

const mapDispatchToProps = (dispatch : ThunkDispatch<State, unknown, Action>) => ({
  setResolution: (camera: string,resolution: [number,number]) => dispatch(setResolution(camera,resolution)),
  setReplayTimestamp: (ts: number) => dispatch(setReplayTimestamp(ts))
});

const connector = connect(mapStateToProps, mapDispatchToProps,null,{forwardRef:true});

type Props = {
  onStateChanged: (state:PlayerState)=>void,
  camera: string,
  video: Video,
  children: ReactNode
} & ConnectedProps<typeof connector>;

export class VideoPlayer extends Component<Props,PlayerState> {
  private readonly player = React.createRef<HTMLVideoElement>();
  private lastTs?: number;

  constructor(props: Props, context: any) {
    super(props, context);

    let speed = 1;
    let speeds = [1,2];
    if(!isIE11 && !isEdge && !isIOS && !isDesktopSafari) {
      speeds.push(4,8,16);
      speed = 1;
    }

    this.state = {
      loading: true,
      playing: false,
      speeds,
      showPreview: true,
      videoReady:false,
      previewFailed: false,
      speed,
      unavailable: false
    };

    this.updatePreview();
  }

  componentDidUpdate(props: Props) {
    const {timestamp} = props;
    const {playing,showPreview} = this.state;
    if(showPreview && timestamp !== this.props.timestamp)
      this.setState({previewFailed:false});
    if(!playing && timestamp !== this.props.timestamp) {
      this.updatePreview();
    }
    if (playing && this.getVideoTsFromAbsoluteTs() !== this.lastTs){
      this.navigate();
    }

    this.props.onStateChanged(this.state);
  }

  componentDidMount() {
    (window as any).player = this.player.current;
    this.props.onStateChanged(this.state);
  }

  updatePreview(showLoader = true) {
    return Promise.resolve();
    const {timestamp,camera} = this.props;

    if(showLoader){
      this.setState({loading:true});
    }
    return loadImageAjax(camera,timestamp as number).then(url=>{
      if(timestamp === this.props.timestamp)
        this.setState({previewUrl:url});
    }).finally(()=>{
      this.setState({loading:false});
    })
  }

  async onLoadedMetadata() {
    let player = this.player.current;
    if(!player)
      return;
    let resolution: [number,number] = [player.videoWidth, player.videoHeight];
    this.props.setResolution(this.props.camera,resolution);
    this.setSpeed(this.state.speed);
    this.setState({videoReady:true});
    // if(isIOS) {
    //   await player.play();
    //   player.pause();
    //
    //   //timeupdate fired in a moment after pause
    //   setTimeout(()=>{
    //     this.setState({videoReady:true},()=>this.play());
    //   },1000)
    // }else
    //   this.setState({videoReady:true},()=>this.play());
  }

  onTimeChange() {
    const {setReplayTimestamp,turnaround,video} = this.props;
    const player = this.player.current;

    if(!this.state.videoReady || !player || !turnaround)
      return;

    this.lastTs = Math.floor(player.currentTime);
    let ts = player.currentTime*1000*video.speed;
    setReplayTimestamp(turnaround.start + ts);

    console.log(player.currentTime, player.duration);
    if(this.state.playing && player.currentTime === player.duration){
      this.play();
    }
  }

  onFail() {
    this.setState({unavailable:true});
  }

  onWaiting() {
    this.setState({loading: true})
  }

  onSeeked() {
    this.setState({loading: false})
  }

  onCanPlay() {
    this.setState({loading: false})
  }

  navigate() {
    let player = this.player.current;
    if(!player)
      return;
    player.currentTime = this.getVideoTsFromAbsoluteTs() || 0;
  }

  getVideoTsFromAbsoluteTs() {
    const {timestamp,turnaround,video} = this.props;
    if(!timestamp || !turnaround)
      return;
    return Math.floor((timestamp - turnaround.start)/(1000*video.speed));
  }

  setSpeed(speed: number) {
    const player = this.player.current;
    if(!player)
      return;
    this.setState({speed});
    player.playbackRate = speed;
  }

  getSpeed():number {
    return this.state.speed;
  }

  play() {
    const player = this.player.current;
    if(!player)
      return;
    let {playing} = this.state;
    if(playing){
      player.pause();
      this.setState({playing: false});
      this.updatePreview(false).then(()=>this.setState({showPreview: true}));
    } else {
      let ts = this.getVideoTsFromAbsoluteTs() || 0;
      if(ts === player.currentTime){
        player.play();
        this.setState({playing:true,showPreview: false});
      }else {
        this.setState({loading:true,playing:true});
        player.addEventListener('seeked',()=>{
          player.play();
          this.setState({showPreview:false,loading: false});
        },{once:true});
        player.currentTime = ts;
      }

      if(Math.abs(player.currentTime - player.duration)<1){
        player.currentTime = 0;
      }
    }
  }

  onPreviewFailed() {
    this.setState({previewFailed:true});
    this.navigate();
  }

  renderVideo() {
    let {token,video} = this.props;
    let {showPreview,previewFailed} = this.state;

    let url  = video.url + '?token=' + token;
    return (
      <video
        className={classnames({invisible: showPreview && !previewFailed})}
        width="100%"
        // muted
        playsInline
        onLoadedMetadata={ev => this.onLoadedMetadata()}
        onCanPlay={() => this.onCanPlay()}
        onWaiting={() => this.onWaiting()}
        onSeeked={()=> this.onSeeked()}
        onTimeUpdate={() => this.onTimeChange()}
        onClick={ev => ev.preventDefault()}
        onError={ev => this.onFail()}
        ref={this.player}
      >
        <source src={url} type="video/mp4"/>
      </video>
    )
  }

  render() {
    let {loading,showPreview,previewUrl,previewFailed,unavailable} = this.state;
    let {camera,children} = this.props;

    if(unavailable)
      return <FrameUnavailable msg={'Video is unavailable'}/>;

    return (
      <FrameContainer camera={camera}>
        {loading && <Spinner/>}
        {this.renderVideo()}
        {!loading && children}
      </FrameContainer>
    )
  }
}

export default connector(VideoPlayer);