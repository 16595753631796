import { User } from "oidc-client";
import update from 'immutability-helper';
import {
  ADD_STANDS,
  RESET_NETWORK_ERRORS,
  SERVER_UNAVAILABLE, SET_ALARUM_CONNECTED,
  SET_CURRENT_STAND_ID,
  SET_IMG_TOKEN, SET_PUSH_SUBSCRIPTIONS,
  SET_USER,
  SWITCH_TIME_FORMAT, TOGGLE_EXPORT_MODAL,
  TOGGLE_PUSH_SUBSCRIPTIONS_MODAL,
} from "./constants";
import {maxTriesUntilError, timeFormat} from "../services/config";
import {TIME_FORMAT_KEY} from "../services/constants";
import Stand from "../models/stand";
import {ReducerAction} from "react";
import ReduxAction from "../models/reduxAction";
import { AppState } from "../models/state";

const initialState : AppState = {
  networkFailCounter: 0,
  timeFormat: localStorage.getItem(TIME_FORMAT_KEY) || timeFormat,
  showPushSettingsModal: false,
  showExportModal: false,
  alarumConnected: false,
  sidebarCollapsed: false,
  stands: [
    {
      id: "santa",
      label: "Santa",
      cameras: ["camera"]
    }
  ],
  serverUnavailable: false,
};

export default function(state: AppState = initialState, action: ReduxAction): AppState {
  switch (action.type) {
    case ADD_STANDS:
      return {...state, stands: action.stands};
    case SET_CURRENT_STAND_ID:
      state = update(state, {
        currentStandId: { $set: action.id },
        serverUnavailable: {$set: false},
        networkFailCounter: {$set: 0}
      });
      return state;
    case RESET_NETWORK_ERRORS:
      return {...state,serverUnavailable: false,networkFailCounter:0};
    case SERVER_UNAVAILABLE:
      if(!action.value)
        return {...state, serverUnavailable: false, networkFailCounter: 0};
      let counter = state.networkFailCounter + 1;
      return {...state, serverUnavailable: counter > maxTriesUntilError, networkFailCounter: counter};
    case SET_USER:
      return {...state,user: action.user};
    case SET_IMG_TOKEN:
      return {...state, imgToken: action.token};
    case SWITCH_TIME_FORMAT:
      state = update(state, {
        timeFormat: { $set: action.format }
      });
      return state;
    case TOGGLE_PUSH_SUBSCRIPTIONS_MODAL:
      return {...state, showPushSettingsModal: !state.showPushSettingsModal};
    case SET_ALARUM_CONNECTED:
      return {...state, alarumConnected: action.value};
    case TOGGLE_EXPORT_MODAL:
      return {...state, showExportModal: !state.showExportModal};
    case "toggle-sidebar":
      return {...state, sidebarCollapsed: !state.sidebarCollapsed};
    default:
      return state;
  }
}