import React, {Component} from 'react';

import './style.scss';
import {SIDEBAR_VISIBLE_STORAGE_KEY} from "../../services/constants";
import SidebarHeader from "./header";
import SidebarToolbar from "./toolbar";
import SidebarContent from "./content";
import {connect, ConnectedProps} from "react-redux";
import {selectCurrentStandId} from "../../redux/selectors";
import SidebarFooter from "./footer";
import Alert from "../../models/alert";
import {getAlerts} from "../../services/api";
import {updateRate} from "../../services/config";
import {createStructuredSelector} from "reselect";
import store, { State } from '../../redux/store';
import {minBy, sortBy} from "lodash";
import {getTime} from "../../redux/realtimeReducer";

export const SidebarContext = React.createContext<SidebarContext>(null as any);

type SidebarContext = {
  collapsed: boolean,
  toggle: ()=>void,
  allStands: boolean,
  toggleAllStands: (val: boolean)=>void,
  alerts: Alert[] | null,
  loadMore: ()=>void,
  isLoadingMore: boolean
}

type LocalState = {
  allStands: boolean,
  collapsed: boolean,
  alerts: Alert[] | null,
  until: string | null
}

type ReduxProps = {
  standId?: string
}

const mapStateToProps = createStructuredSelector<State, ReduxProps>({
  standId: selectCurrentStandId
});

const connector = connect(mapStateToProps);

function makeAlert(ts:any,text:any,group:any): Alert {
  return {
    ts: getTime(ts),
    alertType: text,
    standId: 'santa',
    retracted:false,
    group,
    data: null,
    id: Math.random().toString(),
    turnaroundId: "1"
  }
}

let alerts:Alert[] = [
  makeAlert("00:00:02","No masks on elfs","Safety"),
  makeAlert("00:00:17","No safety vests on elfs","Safety"),
  makeAlert("00:00:22","Chocks placed","Safety"),
  makeAlert("00:01:14","Chocks removed","Safety"),
  makeAlert("00:00:22","Gifts are secured","Safety"),

  makeAlert("00:00:22","Instruments are holly-jolly","Process"),
  makeAlert("00:00:41","Flight plan received","Process")
]
alerts = sortBy(alerts,"ts");

class Sidebar extends Component<ConnectedProps<typeof connector>,LocalState> {
  mounted = true
  isLoadingMore = false
  state = {
    allStands: true,
    collapsed: false,//!!parseInt(localStorage.getItem(SIDEBAR_VISIBLE_STORAGE_KEY) || "0"),
    until: null,
    alerts
  }
  timerId?: NodeJS.Timeout

  componentDidMount() {
    // this.update();
    this.setState({allStands: !this.props.standId})
  }

  componentWillUnmount() {
    if(this.timerId)
      clearTimeout(this.timerId);
  }

  update = () => {
    // getAlerts({until: this.state.until}).then(items=>{
    //   if(!this.mounted)
    //     return;
    //   this.setState({alerts: items})
    // }).finally(()=>{
    //   this.timerId = setTimeout(this.update,updateRate);
    // })
  }

  loadMore() {
    if(!this.state.alerts || this.isLoadingMore)
      return;

    this.isLoadingMore = true;

    let oldestAlert: Alert | undefined = minBy(this.state.alerts, a=>a.ts);
    if(!oldestAlert)
      return;

    getAlerts({before:oldestAlert.id}).then(items=> {
      let oldestAlert = minBy(items, a=>a.ts);
      if(oldestAlert)
        this.setState({until: oldestAlert.id});
    }).finally(()=>{
      this.isLoadingMore = false;
    })

    this.forceUpdate();
  }

  toggle = () => {
    const {collapsed} = this.state;
    this.setState({collapsed: !collapsed});
    localStorage.setItem(SIDEBAR_VISIBLE_STORAGE_KEY, collapsed ? "0" : "1")
    store.dispatch({type: 'toggle-sidebar'});
  }

  render() {
    const {collapsed,alerts,allStands} = this.state;

    const contextValue: SidebarContext = {
      collapsed,
      toggle: this.toggle,
      allStands,
      toggleAllStands: (val: boolean)=>this.setState({allStands: val}),
      alerts,
      loadMore: () => this.loadMore(),
      isLoadingMore: this.isLoadingMore
    }
    if(contextValue.alerts)
      contextValue.alerts = contextValue.alerts.filter(a=>!a.retracted);

    return (
      <SidebarContext.Provider value={contextValue}>
        <SidebarHeader/>
        <SidebarToolbar/>
        <SidebarContent/>
        <SidebarFooter/>
      </SidebarContext.Provider>
    )
  }
}

export default connector(Sidebar);