import React, {Component} from 'react';
import Logo from "../Logo";
import './style.scss';
import Dropdown, {DropdownItem} from "../Dropdown";
import {useDispatch, useSelector} from "react-redux";
import {logout, switchTimeFormat} from "../../redux/actions";
import {selectTimeFormat, selectUser} from "../../redux/selectors";
import {LOCAL, UTC} from "../../services/time";
import {logoUrl} from "../../services/config";
import userPic from "./user-pic.png";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../../redux/store";
import {Action} from "redux";

const Header: React.FC = () => {
  const dispatch: ThunkDispatch<State,any,Action> = useDispatch();
  const timeFormat = useSelector(selectTimeFormat);
  const user = useSelector(selectUser);

  return (
    <header>
      {logoUrl ? <img className={'logo'} src={logoUrl}/> : <Logo/>}
      <Dropdown
        className={'user-dropdown'}
        title={
          <>
            Elf Duty Manager
            <i className="far fa-angle-down"/>
            <img src={userPic}/>
          </>
        }
      >
        <DropdownItem
          className={'logout-btn'}
          onSelect={() => dispatch(logout())}
        >
          Log out
          &nbsp;
          <i className="far fa-sign-out"></i>
        </DropdownItem>
        <div className={'dropdown_separator'}/>
        <div className={'dropdown_section-title'}>TIME ZONE</div>
        <DropdownItem
          onSelect={() => dispatch(switchTimeFormat(UTC))}
          className={'timeformat-option'}
          active={timeFormat === UTC}
        >
          UTC
        </DropdownItem>
        <DropdownItem
          className={'timeformat-option'}
          onSelect={() => dispatch(switchTimeFormat(LOCAL))}
          active={timeFormat === LOCAL}
        >
          Local
        </DropdownItem>
      </Dropdown>
    </header>
  )
}

export default Header;