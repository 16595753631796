import React from 'react';

export const ERROR_MESSAGE = <>Assaia ApronAI is unavailable.<br/>Please try later...</>;

export const TIME_FORMAT_KEY = 'TIME_FORMAT';

export const EMPTY_APRON_LABEL = 'No aircraft on stand';

export const UNAUTHORIZED_TURN_SORRY = 'Sorry, this turnaround is not for you';

export const NOT_AVAILABLE = 'N/A';

export const OTHERS_GROUP = 'Other';

export const SIDEBAR_VISIBLE_STORAGE_KEY = 'sidebar_collapsed';