import React, {useContext, useRef} from 'react';
import {useSelector} from "react-redux";
import classnames from "classnames";

import './style.scss';
import {selectCurrentStandId, selectReplayTimestamp} from "../../redux/selectors";
import {SidebarContext} from "./index";
import Spinner from "../Spinner";
import Section from "../Section";
import Alert from "../../models/alert";
import {groups, labels} from "./constants";
import { useHistory } from 'react-router-dom';
import TimeFormatter from "../TimeFormatter";


const SidebarContent: React.FC = ()=> {
  const history = useHistory();
  const standId = useSelector(selectCurrentStandId);
  const container = useRef<HTMLDivElement | null>(null);
  const ts = useSelector(selectReplayTimestamp);
  let {allStands,alerts,collapsed, loadMore, isLoadingMore} = useContext(SidebarContext);

  if(!alerts)
    return (
      <div className={classnames('sidebar_content',{collapsed})}>
        <Spinner/>
      </div>
    );

  if(!allStands)
    alerts = alerts.filter(i=>i.standId === standId);

  if(collapsed)
    return (
      <div className={'sidebar_content collapsed'}>
        <div className={'sidebar_badge'}>
          {alerts.length}
        </div>
      </div>
    );

  const getGroup = (item:Alert) => item.group;//groups.find(g=>g.types.includes(item.alertType))?.key || "Unknown";
  const getLabel = (item:Alert) => {
    return item.alertType;
    // let val = labels[item.alertType];
    // if(!val)
    //   return item.alertType
    // if(typeof val === 'string')
    //   return val;
    // return val(item);
  }
  const navigate = (item:Alert) => history.push(`/${item.standId}/${item.turnaroundId}`);
  const onScroll = ()=> {
    if(!container.current)
      return;
    let el = container.current;
    if(Math.abs(el.scrollHeight - el.scrollTop - el.offsetHeight) < 20){
      loadMore();
    }
  }

  console.log("ts", ts ? new Date(ts) : "")

  return (
    <div className={'sidebar_content'} onScroll={onScroll} ref={container}>
      {/*<Section title={`General(${alerts.length})`}>*/}
        {alerts.filter(a=>a.ts <= (ts || Date.now())).map(alert=>
          <div className={'alert'} key={alert.id}>
            <h3>
              <a className={'flight-info'} onClick={()=>navigate(alert)}>
                {alert.standId} {alert.outboundFlightNumber}
                <i className="fas fa-long-arrow-alt-up arrow"/>
              </a>
                <span className={'time'}>
                {getGroup(alert)}: <TimeFormatter format={'DD MMM HH:mm:ss'} time={alert.ts}/>
              </span>
            </h3>
            <p>
              {getLabel(alert)}
            </p>
          </div>
        )}
    </div>
  )
}

export default SidebarContent;