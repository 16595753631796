import React, {PropsWithChildren, useContext} from "react";
import {useSelector} from "react-redux";
import classnames from 'classnames';

import {selectAspectRatio} from "../../redux/selectors";
import './style.scss';

export type ZoomContextValue = {
  zoomed: boolean,
  toggle: ()=>void
}
export const ZoomContext = React.createContext<ZoomContextValue | null>(null);

type Props = {
  camera: string,
  className?: string
}

const FrameContainer : React.FC<Props>=(props:PropsWithChildren<Props>)=> {
  const {camera,children,className} = props;
  const ratio = useSelector(selectAspectRatio)[camera] || 1.78;
  const zoomContext = useContext(ZoomContext);
  const style = {
    paddingTop: 100/ratio + '%',
  };

  return (
    <div className={classnames('frame-container',className)} style={style}>
      <div className={'children'}>
        {children}
      </div>
    </div>
  )
};

export default FrameContainer;