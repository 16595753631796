import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory,useParams} from "react-router-dom";

import Spinner from "../Spinner";
import {
  selectTurnarounds,
  selectCurrentTurnaround,
  selectCurrentStandId,
  selectFrameModalData,
  selectCurrentTurnaroundId,
  selectResolution, selectLastImageTimestamp, selectSidebarCollapsed
} from "../../redux/selectors";
import Timeline from "../Timeline";
import TurnaroundStream from "../TurnaroundStream";
import TurnaroundVideo from "../TurnaroundVideo";
import FrameModal from "../FrameModal";
import {
  searchTurn,
  setCurrentTurnaround
} from "../../redux/actions";
import "./style.scss";
import TurnaroundInfo from "../TurnaroundInfo/container";
import ErrorBoundary from "../ErrorBoundary";
import TurnaroundAlerts from "../TurnaroundAlerts";
import { ZoomContext } from '../FrameContainer';
import {EMPTY_APRON_LABEL, UNAUTHORIZED_TURN_SORRY} from "../../services/constants";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../../redux/store";
import {Action} from "redux";
import FrameUnavailable from "../FrameUnavailable";
import Section from "../Section";
import TurnaroundTiming from "../TurnaroundTiming";
import {showTurnaroundTimingPanel} from "../../services/config";
import {isMobile} from "../../services/platform";

function useTurnaroundId() {
  const dispatch: ThunkDispatch<State,any,Action> = useDispatch();
  const turnarounds = useSelector(selectTurnarounds);
  const {turnId} = useParams<RouterParams>();
  const history = useHistory();
  const standId = useSelector(selectCurrentStandId);

  useEffect(()=>{
    history.push(`/${standId}/${turnarounds[0].id}`);
  },[turnId])

  useEffect(()=>{
    if(!turnarounds)
      return;
    if(!turnId){
      dispatch(setCurrentTurnaround(turnId));
      return;
    }
    const turn = turnarounds.find(t=>t.id === turnId);
    if(turn && !turn.end && turnarounds.indexOf(turn) === 0) {
      history.push(`/${standId}`);
      return;
    }
    if(!turn){
      dispatch(searchTurn(turnId)).then((turn)=>{
        if(turn && turn.authorized)
          dispatch(setCurrentTurnaround(turnId));
        else
          history.push(`/${standId}`);
      }).catch(()=>history.push(`/${standId}`));
    }else{
      if(turn.authorized)
        dispatch(setCurrentTurnaround(turnId));
      else
        history.push(`/${standId}`);
    }
  },[turnId]);
}

type RouterParams = {
  turnId?:string
}

const Turnaround: React.FC = () => {
  useTurnaroundId();
  const ref = useRef<HTMLDivElement>(null);
  const videoResolution = useSelector(selectResolution);
  const frameModalData = useSelector(selectFrameModalData);
  const turnaround = useSelector(selectCurrentTurnaround);
  const tmp = useSelector(selectSidebarCollapsed);
  const lastImageTs = useSelector(selectLastImageTimestamp);
  const {turnId} = useParams<RouterParams>();
  const [zoomed, setZoomed] = useState(false);
  const [videoCellWidth, setVideoCellWidth] = useState<number | null>(null);

  const [windowWidth,setWindowWidth] = useState(window.innerWidth);

  useEffect(()=>{
    window.addEventListener('resize',()=>{
      setWindowWidth(window.innerWidth);
    })
  },[])

  useEffect(()=>{
    // if(!zoomed){
    //   setVideoCellWidth(null);
    //   return;
    // }
    if(!ref.current || !videoResolution.camera)
      return;
    console.log(videoResolution)
    let videoWidth = Math.max(...Object.values(videoResolution).map(i=>i[0]));
    videoWidth = Math.min(videoWidth, ref.current.offsetWidth-400);
    let videoHeight = videoWidth*videoResolution.camera[1]/videoResolution.camera[0];
    if(!isMobile && videoHeight > window.innerHeight*0.3) {
      videoHeight = window.innerHeight*0.3;
      videoWidth = videoHeight*videoResolution.camera[0]/videoResolution.camera[1];
    }
    if(isMobile)
      setVideoCellWidth(null);
    else
      setVideoCellWidth(videoWidth);
  },[zoomed,videoResolution,tmp,windowWidth]);

  //sync is from url and id in store
  let turnIdFromStore = useSelector(selectCurrentTurnaroundId);
  if((turnId !== turnIdFromStore) || (turnaround && !turnaround.loaded) ){
    return <Spinner/>;
  }

  return (
    <div className={'turnaround'} ref={ref}>
      {turnaround && turnaround.authorized && <TurnaroundInfo/>}
      {turnaround && !turnaround.authorized && <div className={'empty-apron'}>{UNAUTHORIZED_TURN_SORRY}</div>}
      {!turnaround && <div className={'empty-apron'}>{EMPTY_APRON_LABEL}</div>}

      <div className={'video-cell'} style={{width:videoCellWidth || undefined}}>
        <ZoomContext.Provider value={{toggle: ()=>setZoomed(!zoomed), zoomed}}>
          {!turnIdFromStore && (!turnaround || turnaround.authorized) && <TurnaroundStream/>}
          {turnIdFromStore && <TurnaroundVideo/>}
          {turnaround && !turnaround.authorized && <FrameUnavailable hideText={true}/>}
          {turnaround && turnaround.authorized && <TurnaroundAlerts/>}
        </ZoomContext.Provider>
      </div>

      <div className={'sections'}>
        {showTurnaroundTimingPanel && turnaround &&  (
          <Section title={'Time Prediction'} className={'timing-section'}>
            <TurnaroundTiming/>
          </Section>
        )}

        <Section title={'Progress chart'} className={'timeline-section'}>
          <ErrorBoundary msg={'Progress chart is broken'} showRefreshBtn={false}>
            <Timeline/>
          </ErrorBoundary>
        </Section>
      </div>
      {frameModalData && <FrameModal/>}
    </div>
  );
}

export default Turnaround;