import React from 'react';
import classnames from 'classnames';
import {createPortal} from 'react-dom'
import './style.scss';

type Props = {
  onClose: ()=>void
  className?: string
}

const Modal: React.FC<Props> = ({children,onClose,className}) => {
  const root = document.getElementById('root');
  if(!root)
    return null;
  return createPortal(
    <div className={classnames(['modal-container', className])} onClick={() => onClose()}>
      <div className={"modal"} onClick={ev => ev.stopPropagation()}>
        {children}
      </div>
    </div>
  ,root)
};

export default Modal;