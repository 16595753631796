import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classnames from "classnames";

import {
  selectCameras,
  selectColors,
  selectReplayTimestamp,
  selectCurrentTurnaround,
  selectEvents
} from "../../redux/selectors";
import VideoPlayer, {PlayerState, VideoPlayer as UnwrapedVideoPlayer} from "./player";
import {formatTime} from "../../services/time";
import Dropdown,{DropdownItem} from "../Dropdown";
import {setReplayTimestamp} from "../../redux/actions";
import {disableVideoStreamBBox} from "../../services/config";
import BBox from "../BBox";
import Frame from "../Frame";
import Turnaround from "../../models/turnaround";
import Spinner from "../Spinner";

const TurnaroundVideo:React.FC = ()=> {
  const dispatch = useDispatch();
  const cameras = useSelector(selectCameras);
  const turnaround = useSelector(selectCurrentTurnaround) as Turnaround;
  const events = useSelector(selectEvents);
  const colors = useSelector(selectColors);
  const [tmp,setTmp] = useState(false);
  const [camera, setCamera] = useState(cameras.find(c=>turnaround.videos[c]) || cameras[0]);
  const [ready, setReady] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [speeds, setSpeeds] = useState<number[]>([]);
  const videoInProgress = !turnaround.videos[camera];
  const timestamp = useSelector(selectReplayTimestamp) as number;
  const progress = timestamp ? (timestamp - turnaround.start)/((turnaround.end as number)-turnaround.start) : 0;
  const progressBar = useRef<HTMLInputElement>(null);
  const player = useRef<UnwrapedVideoPlayer>(null);

  const onProgressClick = (ev: React.MouseEvent) => {
    if(!progressBar.current)
      return;
    const rect = progressBar.current.getBoundingClientRect();
    let val = (ev.clientX - rect.left)/(rect.width);
    val = turnaround.start + val*((turnaround.end as number) - turnaround.start);
    dispatch(setReplayTimestamp(val));
  };

  const onPlayerStateChanged = (state: PlayerState) => {
    setReady(state.videoReady);
    setPlaying(state.playing);
    setSpeed(state.speed);
    setSpeeds(state.speeds)
  };

  const getBBox = () => {
    if(disableVideoStreamBBox || !player.current || !timestamp)
      return null;

    let displayPeriod = player.current ? 7000 * player.current.getSpeed() : 5000;
    let nearEvents = events.filter(e=>e.timestamp <= timestamp && timestamp - e.timestamp < displayPeriod);
    nearEvents = nearEvents.filter(e=>e.detection.bbox && e.detection.bbox.camera === camera);
    let boxes = nearEvents.map(event=>({
      label: event.label,
      bbox: event.detection.bbox,
      color: colors[event.detection.type],
      id: event.id
    }));
    return boxes.map(bbox=>(
      // @ts-ignore
      <BBox {...bbox} key={bbox.id}/>
    ));
  };

  if(videoInProgress) {
    return (
      <div className={'turnaround-stream finished-turnaround-stream'}>
        <Frame onFail={()=>{}} camera={camera} timestamp={turnaround.start}>
          <div className={'processing-alert'}>Replay video is processing now,<br/>please wait</div>
        </Frame>
        <div className={'toolbar'}>
          <div className={'cameras'}>
            {cameras.map(c=>(
              <a className={classnames({active:camera === c})} onClick={()=>setCamera(c)} key={c}>{c}</a>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={'turnaround-stream finished-turnaround-stream'}>
      <VideoPlayer
        video={turnaround.videos[camera]}
        camera={camera}
        key={camera}
        ref={player}
        onStateChanged={onPlayerStateChanged}
      >
        {getBBox()}
      </VideoPlayer>

      {ready && !tmp && (
        <a className={'big-play-btn'} onClick={()=>{
          setTmp(true);
          player.current && player.current.play()
        }}>
          <i className="fas fa-play"/>
        </a>
      )}

        <div className={'toolbar'}>
          <div className={'progress'} onClick={ev=>onProgressClick(ev)} ref={progressBar}>
            <div style={{width:progress*100 + '%'}}/>
          </div>

          <div className={'cameras'}>
            {cameras.map(c=>(
              <a className={classnames({active:camera === c})} onClick={()=>setCamera(c)} key={c}>{c}</a>
            ))}
          </div>

          <Dropdown title={speed + 'X'} align={'left'} className={'video-speed-dropdown'}>
            {speeds.map(s=>(
              <DropdownItem
                active={speed === s}
                key={s}
                onSelect={() => player.current && player.current.setSpeed(s)}
              >
                {s}
              </DropdownItem>
            ))}

          </Dropdown>

          <div className={'time'}>
            {formatTime(timestamp, false)}
          </div>
          {/*<div className={'live-indicator'}>*/}
          {/*  <i className="fas fa-circle"></i>*/}
          {/*  LIVE*/}
          {/*</div>*/}

          {ready
            ? (
              <a className={'play-btn'} onClick={()=>player.current && player.current.play()}>
                {playing ? <i className="fas fa-pause"/> : <i className="fas fa-play"/>}
              </a>
            ) : (
              <span className={'play-btn'}>
                <Spinner/>
              </span>
            )
          }


        </div>
    </div>
  )
}

export default TurnaroundVideo;