import React from 'react';
import "./style.scss"
import useAutoRefresh from "../../services/react";
import TimeFormatter from '../TimeFormatter';
import {NOT_AVAILABLE} from "../../services/constants";
import {formatFlightNumber} from "../../services/data";
import Turnaround from "../../models/turnaround";

type Props = {
  standId?: string
  turnaround: Turnaround
}

const TurnaroundInfo : React.FC<Props> = (props: Props) => {
  const {standId, turnaround: {inboundFlight, outboundFlight}} = props;
  useAutoRefresh();

  return (
    <div className={'turnaround-info'}>
      <div className={'aircraft-gate-cell'}>
        {standId && <div className={'stand-id'}>{standId}</div>}
        <span className={'aircraft-type'}>SLG (Sleigh)</span>
      </div>

      {/*<div className={'overdue-status'}>*/}
      {/*  <i className="fas fa-question-circle"/>*/}
      {/*  <span>in time</span>*/}
      {/*</div>*/}

      <div className={'in-flight-no'}>
        SC2412
        <i className="fas fa-circle"/>
      </div>

      <div className={'airport-from'}>
        RVN
        <i className="far fa-arrow-right"/>
      </div>

      <div className={'out-flight-no'}>
        SC2512
      </div>

      <div className={'airport-to'}>
        <i className="far fa-arrow-right"/>
        WRL
      </div>

      {/*<div className={'sobt-label'}>*/}
      {/*  sobt*/}
      {/*</div>*/}
      {/*<div className={'sobt-value'}>*/}
      {/*  {outboundFlight?.scheduledOffBlockTime*/}
      {/*    ? <TimeFormatter format='HH:mm' time={outboundFlight?.scheduledOffBlockTime}/>*/}
      {/*    : NOT_AVAILABLE*/}
      {/*  }*/}
      {/*</div>*/}
      {/*<div className={'pobt-label'}>*/}
      {/*  pobt*/}
      {/*</div>*/}
      {/*<div className={'pobt-value'}>*/}
      {/*  {outboundFlight?.predictedOffBlockTime*/}
      {/*    ? <TimeFormatter format='HH:mm' time={outboundFlight?.predictedOffBlockTime}/>*/}
      {/*    : NOT_AVAILABLE*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
  );
};

export default TurnaroundInfo;